import React from "react";
import { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import Home from "./screens/Home";
import Login from "./screens/Login";
// import { hotjar } from "react-hotjar";

function App() {
  const [user, loginWithGoogle, logOut, error, anon] = useAuth();

  // useEffect(() => {
  //   hotjar.initialize(3396971, 6);
  // }, []);

  useEffect(() => {
    window.addEventListener(
      "contextmenu",
      function (e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  function refreshPage() {
    window.location.reload();
  }

  if (navigator.onLine !== true) {
    return (
      <div className="p-12">
        <div className="my-12">
          <h1 className="text-xl text-red-800 mb-4">
            The network is disconnected. Connect and try again
          </h1>
          <button
            className="bg-blue-700 px-3  hover:bg-red-600 hover:text-white text-white px-2 py-1 rounded-md text-sm sm:text-base"
            onClick={refreshPage}
          >
            Refresh
          </button>
        </div>
      </div>
    );
  }

  //error while logging in
  if (error)
    return (
      <div className="p-12">
        <div className="my-12">
          <h1 className="text-xl text-red-800 mb-4">{error}</h1>
          <button
            className="bg-blue-700 px-3  hover:bg-red-600 hover:text-white text-white px-2 py-1 rounded-md text-sm sm:text-base"
            onClick={loginWithGoogle}
          >
            Try again
          </button>
        </div>
      </div>
    );

  //Not logged in
  if (user === false) {
    return <Login loginWithGoogle={loginWithGoogle} signInAnon={anon} />;
  }

  //state of loading
  if (user === null) {
    return <div className="spinner h-screen w-screen" />;
  }

  //logged in
  else
    return (
      <Home
        logOut={logOut}
        userId={user.uid}
        name={user.displayName}
        isAnon={user.isAnonymous}
      />
    );
}

export default App;
